import axios from 'axios';
import { CmsPageResponse, ContactUsResponse } from '../ts/interfaces/CmsPageResponse';
import { ContactUsModel } from '../ts/classes/ContactUsModel';
import { getScript } from '@bepro-travel/fe.utils';

let firstLoad = true;
export const getPage = async (location?: string): Promise<CmsPageResponse | undefined> => {
  try {
    let data;
    let url = 'data';
    if (location) {
      url = `data/${location}`;
    }

    const siteData = sessionStorage.getItem('siteData');
    const params = { language: 'he', loadStaticSections: !siteData };

    if (url === 'data') {
      data = (await axios.post<CmsPageResponse>(url, params)).data;
    } else {
      data = (await axios.get<CmsPageResponse>(url, { params })).data;
    }

    if (siteData) {
      data.pageHeader = JSON.parse(siteData).pageHeader;
      data.pageFooter = JSON.parse(siteData).pageFooter;
      data.siteConfig = JSON.parse(siteData).siteConfig;
    } else if (data.pageHeader && data.pageFooter && data.siteConfig) {
      sessionStorage.setItem(
        'siteData',
        JSON.stringify({
          pageHeader: data.pageHeader,
          pageFooter: data.pageFooter,
          siteConfig: data.siteConfig,
        })
      );
    }

    if (data.siteConfig && firstLoad) {
      firstLoad = false;
      if (data.siteConfig.anotherAnalytics) {
        const script = getScript(data.siteConfig.anotherAnalytics);
        document.body.appendChild(script);
      }

      if (data.siteConfig.googleAnalytics) {
        const script = getScript(data.siteConfig.googleAnalytics);
        document.body.appendChild(script);
      }

      if (data.siteConfig.googleTagManager) {
        const script = getScript(data.siteConfig.googleTagManager);
        document.body.appendChild(script);
      }

      if (data.siteConfig.script1) {
        const script = getScript(data.siteConfig.script1);
        document.body.appendChild(script);
      }

      if (data.siteConfig.script2) {
        const script = getScript(data.siteConfig.script2);
        document.body.appendChild(script);
      }

      if (data.siteConfig.script3) {
        const script = getScript(data.siteConfig.script3);
        document.body.appendChild(script);
      }
    }

    if (data.page) {
      if (data.page.languages?.length) {
        const lang = localStorage.getItem('language') || 'he';
        const details = lang === 'he' ? data.page.languages[0] : data.page.languages[1];

        const seoTitle = details.seo.title || data.page.languages[0].seo.title || '';
        document.title = seoTitle ? seoTitle + ' | BePro Travel' : 'BePro Travel';

        const canonicalLink = details.seo.canonical || data.page.languages[0].seo.canonical;
        if (canonicalLink) {
          const canonical = document.createElement('link');
          canonical.rel = 'canonical';
          canonical.href = canonicalLink;
          document.head.appendChild(canonical);
        }

        const keywordsDesc = details.seo.keywords || data.page.languages[0].seo.keywords;
        if (keywordsDesc) {
          const keywordsMeta = document.createElement('meta');
          keywordsMeta.name = 'keywords';
          keywordsMeta.content = keywordsDesc;
          document.head.appendChild(keywordsMeta);
        }

        const descDesc = details.seo.description || data.page.languages[0].seo.description;
        if (descDesc) {
          const descMeta = document.createElement('meta');
          descMeta.name = 'description';
          descMeta.content = descDesc;
          document.head.appendChild(descMeta);
        }
      }
    }

    return data;
  } catch (err) {
    const siteData = sessionStorage.getItem('siteData');
    if (siteData) {
      const siteDataObj = JSON.parse(siteData);
      const data = {
        pageHeader: siteDataObj.pageHeader,
        pageFooter: siteDataObj.pageFooter,
        siteConfig: siteDataObj.siteConfig,
      };
      return data as unknown as CmsPageResponse;
    }
    return undefined;
  }
};

export const getLoadings = async (): Promise<CmsPageResponse | undefined> => {
  const loadings = sessionStorage.getItem('allLoadings');
  const language = 'he';

  if (loadings) {
    return;
  } else {
    try {
      const url = 'data/AllLoadings';

      const { data } = await axios.get(url, { params: { language } });

      sessionStorage.setItem('allLoadings', JSON.stringify(data));
    } catch (err) {
      return undefined;
    }
  }
};

export const sendContactUs = async (
  data: ContactUsModel
): Promise<ContactUsResponse | undefined> => {
  try {
    const url = 'Utils/ContactUs';
    return (await axios.post(url, data)).data;
  } catch (err) {
    return undefined;
  }
};

export const getRandomImage = async () => {
  try {
    const response = await axios.get('data/RandomImage');
    return response.data;
  } catch (error) {
    return undefined;
  }
};
