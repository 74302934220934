import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { MainContext } from '../contexts/MainContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { TitleLang } from '../ts/interfaces/CmsPageResponse';
import { Accessibility, ConfigModal, ScreenshotSupport } from '@bepro-travel/fe.shared';
import { dateFormat } from '../utils/i18n';
import { ProposalModal } from '@bepro-travel/fe.hotel';
import { useTranslation } from 'react-i18next';
import { showSuccess } from '@bepro-travel/fe.utils';
import { auth } from '@bepro-travel/fe.shared';

interface HeaderProps {
  notOpenTabWithChildren?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const [urlParams] = useSearchParams();
  const [isOpenProposal, setIsOpenProposal] = useState(false);
  const { proposals, setProposals, header, user, setUser } = useContext(MainContext);
  const [hamburgerState, setHamburgerState] = useState(false);
  const [isHoverElement, setIsHoverElement] = useState(-2);
  const language = localStorage.getItem('language')!;
  const navigate = useNavigate();
  const [clubDiscountText, setClubDiscountText] = useState('');
  const [clubLogoUrl, setClubLogoUrl] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      const data = sessionStorage.getItem('siteData');
      if (data) {
        const siteData = JSON.parse(data);
        setClubDiscountText(siteData.clubDiscountText);
        setClubLogoUrl(siteData.clubLogoUrl);
      }
    }, 1000);
  }, []);

  const onClickProposal = () => {
    setIsOpenProposal(!isOpenProposal);
    closeMenu();
  };

  const onClickLogoff = async () => {
    await auth.logout();
    showSuccess(t('login.off_success'));
    setUser(undefined);
    closeMenu();
  };

  const onPressHanburger = () => {
    document.querySelector('.header-fields')!.classList.toggle('open');
    setHamburgerState(!hamburgerState);
  };

  const closeMenu = (event?: any, index?: number) => {
    if (window.screen.width < 768) {
      if (event && ['path', 'svg'].includes(event.target.tagName)) {
        setIsHoverElement(typeof index === 'number' ? index : -2);
      } else {
        document.querySelector('.header-fields')!.classList.remove('open');
        setHamburgerState(false);
      }
    }
  };

  const onClickHeaderCell = (event?: any, item?: any) => {
    if (props.notOpenTabWithChildren && item?.subPages.length) {
      return;
    }

    if (window.innerWidth < 768) {
      closeMenu(event);
    }

    let url = item?.systemProducts?.toLowerCase().includes('dynamic')
      ? 'dynamic?MatrixConfig=' + item.systemProducts
      : item?.url;

    if (item && item.routeName === 'Pages') {
      url = item.routeName + '/' + item.url;
    }

    if (event.target.tagName.toLowerCase() === 'a') {
      document.querySelector('.header-fields')!.classList.remove('open');
      setHamburgerState(false);
      return;
    }

    navigate(url);
  };

  return (
    <Fragment>
      <div className="header">
        <Link className="header-logo-wr" to="/">
          <img className="header-logo" src={require('../assets/img/logo.png')} alt="Logo" />
          {!!clubLogoUrl && <img className="header-logo-club" src={clubLogoUrl} alt="Club Logo" />}
          {!!clubDiscountText && <div className="header-logo-text">{clubDiscountText}</div>}
        </Link>
        {!!urlParams.get('checkIn') && !!urlParams.get('checkOut') && (
          <div
            className="header-search"
            onClick={() => {
              const element = document.querySelector('.search-wrapper') as HTMLElement;
              element?.classList.toggle('visible-responsive');
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="search-img" />
            <div className="header-search-text">
              <div className="header-search-city">{urlParams.get('city')}</div>
              <div className="header-search-date">
                <div>{dateFormat(urlParams.get('checkIn')!, 'dd MMM')}</div>
                <FontAwesomeIcon icon={faCircle} />
                <div>{dateFormat(urlParams.get('checkOut')!, 'dd MMM')}</div>
              </div>
            </div>
          </div>
        )}
        {!!urlParams.get('departureDate') &&
          !!urlParams.get('arrivalDate') &&
          !!urlParams.get('arrivalCode') && (
            <div
              className="header-search"
              onClick={() => {
                const element = document.querySelector('.search-wrapper') as HTMLElement;
                element.classList.toggle('visible-responsive');
              }}
            >
              <FontAwesomeIcon icon={faSearch} className="search-img" />
              <div className="header-search-text">
                <div className="header-search-city">
                  {urlParams.get('departureCode')} - {urlParams.get('arrivalCode')}
                </div>
                {urlParams.get('departureDate')?.includes('|') ? (
                  <div className="header-search-date">
                    {urlParams
                      .get('departureDate')
                      ?.split('|')
                      .map((date) => (
                        <span>{dateFormat(date, 'dd MMM') + ' - '} </span>
                      ))}
                  </div>
                ) : (
                  <div className="header-search-date">
                    <div>{dateFormat(urlParams.get('departureDate')!, 'dd MMM')}</div>
                    <FontAwesomeIcon icon={faCircle} />
                    <div>{dateFormat(urlParams.get('arrivalDate')!, 'dd MMM')}</div>
                  </div>
                )}
              </div>
            </div>
          )}
        {!!urlParams.get('departureDate') && !!urlParams.get('returnDate') && (
          <div
            className="header-search"
            onClick={() => {
              const element = document.querySelector('.search-wrapper') as HTMLElement;
              element.classList.toggle('visible-responsive');
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="search-img" />
            <div className="header-search-text">
              <div className="header-search-city">
                {urlParams.get('pickup.code')} - {urlParams.get('dropoff.code')}
              </div>
              {urlParams.get('departureDate')?.includes('|') ? (
                <div className="header-search-date">
                  {urlParams
                    .get('departureDate')
                    ?.split('|')
                    .map((date) => (
                      <span>{dateFormat(date, 'dd MMM') + ' - '} </span>
                    ))}
                </div>
              ) : (
                <div className="header-search-date">
                  <div>{dateFormat(urlParams.get('departureDate')!, 'dd MMM')}</div>
                  <FontAwesomeIcon icon={faCircle} />
                  <div>{dateFormat(urlParams.get('returnDate')!, 'dd MMM')}</div>
                </div>
              )}
            </div>
          </div>
        )}

        <span className="hamburger">
          <input
            id="hamburger__toggle"
            type="checkbox"
            onChange={onPressHanburger}
            checked={hamburgerState}
          />
          <label className="hamburger__btn" htmlFor="hamburger__toggle">
            <span></span>
          </label>
        </span>
        <div className="header-fields">
          {user?.isAuth &&
            header?.siteMenu?.mainPages.map((item, index) => (
              <div
                className="header-cell"
                key={index}
                onMouseLeave={() => setIsHoverElement(-2)}
                onMouseEnter={() => setIsHoverElement(index)}
              >
                <span
                  className="header-cell-text"
                  onClick={(event) => onClickHeaderCell(event, item)}
                >
                  {item.titleLang[language as keyof TitleLang] || item.titleLang.he}
                  {!!item.subPages.length && <FontAwesomeIcon icon={faChevronDown} />}
                </span>

                {!!item.subPages.length && (
                  <div
                    className={
                      'header-dropdown header-dropdown-sm' +
                      (isHoverElement === index ? ' open' : '')
                    }
                  >
                    {item.subPages.map((subItem, subIndex) => (
                      <div className="header-dropdown-row" key={subIndex}>
                        <Link
                          to={subItem.routeName + '/' + subItem.url}
                          key={subIndex}
                          onClick={() => closeMenu()}
                        >
                          {subItem.titleLang[language as keyof TitleLang] || subItem.titleLang.he}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          <div className="header-oposite"></div>

          {user?.isAuth && (
            <Fragment>
              <div className="header-cell" onClick={onClickProposal}>
                <span className="header-cell-text">
                  הצעה
                  {!!proposals.length && (
                    <span className="proposal-header">{proposals.length}</span>
                  )}
                </span>
              </div>
              <div className="header-cell" onClick={onClickHeaderCell}>
                <Link to={'my-orders'} className="header-cell-text">
                  ההזמנות שלי
                </Link>
              </div>
              <div className="header-cell mr-5">
                <ConfigModal />
              </div>

              <div className="header-cell mr-5">
                <ScreenshotSupport />
              </div>
              <div className="header-cell" onClick={onClickLogoff}>
                <span className="header-cell-text">התנתק</span>
              </div>
            </Fragment>
          )}
          {!user?.isAuth && (
            <div className="header-cell" onClick={onClickHeaderCell}>
              <Link to={'login'} className="header-cell-text">
                {t('login.login')}
              </Link>
            </div>
          )}
        </div>
      </div>
      <Accessibility />
      {isOpenProposal && (
        <ProposalModal
          onClose={() => setIsOpenProposal(false)}
          proposals={proposals}
          setProposals={setProposals}
        />
      )}
    </Fragment>
  );
};

export default Header;
