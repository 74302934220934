import i18n from './i18n';

export const formatOccupancySrt = (complect: string) => {
  const adult = complect.match(new RegExp('A', 'g'))?.length;
  const child = complect.match(new RegExp('C', 'g'))?.length;
  const res = [
    { label: `${adult} ${i18n.t('search.adults')}`, value: adult },
    { label: `${child} ${i18n.t('search.children')}`, value: child },
  ];
  return res
    .filter((item) => item.value)
    .map((item) => item.label)
    .join(', ');
};

export const formatOccupancySrtByNumbers = (adults: number, children: number) => {
  const res = [
    { label: `${adults} ${i18n.t('search.adults')}`, value: adults },
    { label: `${children} ${i18n.t('search.children')}`, value: children },
  ];
  return res
    .filter((item) => item.value)
    .map((item) => item.label)
    .join(', ');
};

export const setSliderStyles = async () => {
  let isApplied = false;
  let counter = 30;
  if (window.innerWidth < 1250) return;

  const interval = setInterval(() => {
    counter -= 1;
    if (isApplied || counter === 0) {
      clearInterval(interval);
      return;
    }
    const block = document.getElementsByClassName('main-page-search') as any;
    const images = document.querySelectorAll('.main-page-search .carousel img') as any;
    if (images?.length) {
      const img = new Image();
      for (let i = 0; i < images.length; i++) {
        images[i].style.width = '100%';
      }
      isApplied = true;
      img.onload = function () {
        const thisImg: any = this;
        const screenWidth = window.innerWidth;
        thisImg.style.width = '100%';

        const widthRatio = screenWidth / thisImg.width;
        const neededHeight = thisImg.height * widthRatio;
        if (block?.length) {
          block[0].style.height = `${neededHeight}px`;
          block[0].style.width = '100%';
          block[0].style.maxHeight = '80vh';

          const mainPageSearch = document.querySelector('.main-page-search') as any;
          if (mainPageSearch) {
            mainPageSearch.style.height = `${neededHeight}px`;
          }
        }
      };
      const imageSelected = document.querySelectorAll(
        '.main-page-search .carousel .selected img'
      ) as any;
      img.src = imageSelected.length ? imageSelected[0].src : '';
    }
  }, 100);
};

let maxLoadCount = 5;
export const setBgStyles = async () => {
  setTimeout(() => {
    const bgBlock = document.querySelector('.main-page') as any;
    const isBgBlock = bgBlock?.querySelector('.main-page-bg') as any;
    if (isBgBlock) {
      const ratio = 1920 / 1280;

      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const screenRatio = screenWidth / screenHeight;

      if (screenRatio > ratio) {
        const height = screenWidth / ratio;
        if (height > screenHeight) {
          bgBlock.style.minHeight = `${screenHeight - 56}px`;
        } else {
          bgBlock.style.minHeight = `${height}px`;
        }
      }
      maxLoadCount = 5;
    } else if (bgBlock) {
      bgBlock.style.minHeight = 'unset';

      if (maxLoadCount > 0) {
        setBgStyles();
        maxLoadCount -= 1;
      } else {
        maxLoadCount = 5;
      }
    }
  }, 200);
};
