import {
  faFacebook,
  faGoogle,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { openInNewWindow } from '@bepro-travel/fe.utils';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { MainContext } from '../contexts/MainContext';

const Footer = () => {
  const { footer, siteConfig } = useContext(MainContext);
  const location = useLocation();

  const [mockHtml, setMockHtml] = useState('');

  useEffect(() => {
    setTimeout(() => {
      const data = sessionStorage.getItem('siteData');
      if (data) {
        const siteData = JSON.parse(data);

        const mockImg = siteData.clubLogoUrl
          ? `<img src="${siteData.clubLogoUrl}" alt="Logo" class="mb-10" />`
          : '';
        const mockStrong = siteData.clubDiscountText
          ? `<strong>${siteData.clubDiscountText}</strong>`
          : '';
        const newMockHtml = '<div class="footer-sublogo">' + mockImg + mockStrong + '</div>';
        setMockHtml(newMockHtml);
      }
    }, 1000);
  }, []);

  return (
    <Fragment>
      {!location.pathname.includes('hotels/results') &&
        !location.pathname.includes('dynamic-fh/details') &&
        !location.pathname.includes('my-orders') &&
        !location.pathname.includes('dynamic/details') && (
          <div className="footer-wrapper">
            <div className="footer">
              <div className="col-md-3 col-12">
                <img src={require('../assets/img/logo.png')} alt="Logo" className="mb-10" />

                {!!siteConfig && (
                  <Fragment>
                    <div className="footer-social">
                      {!!siteConfig.facebook && (
                        <FontAwesomeIcon
                          icon={faFacebook}
                          onClick={() => openInNewWindow(siteConfig.facebook)}
                        />
                      )}
                      {!!siteConfig.instagram && (
                        <FontAwesomeIcon
                          icon={faInstagram}
                          onClick={() => openInNewWindow(siteConfig.instagram)}
                        />
                      )}
                      {!!siteConfig.twitter && (
                        <FontAwesomeIcon
                          icon={faTwitter}
                          onClick={() => openInNewWindow(siteConfig.twitter)}
                        />
                      )}
                      {!!siteConfig.google && (
                        <FontAwesomeIcon
                          icon={faGoogle}
                          onClick={() => openInNewWindow(siteConfig.google)}
                        />
                      )}
                      {!!siteConfig.youtube && (
                        <FontAwesomeIcon
                          icon={faYoutube}
                          onClick={() => openInNewWindow(siteConfig.youtube)}
                        />
                      )}
                      {!!siteConfig.phone1 && (
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          onClick={() =>
                            openInNewWindow(
                              'https://wa.me/' + siteConfig.phone1.replace(/[^0-9]|^0/g, '')
                            )
                          }
                        />
                      )}
                    </div>
                    {!!siteConfig.languages?.length && !!siteConfig.languages[0].address && (
                      <div className="text-lightest">
                        <FontAwesomeIcon icon={faLocationDot} className="mr-5" />{' '}
                        {siteConfig.languages[0].address}
                      </div>
                    )}
                    {!!siteConfig.phone1 && (
                      <a className="text-lightest" href={'tel:' + siteConfig.phone1}>
                        <FontAwesomeIcon icon={faPhone} className="mr-5" />
                        <span dir="ltr">{siteConfig.phone1}</span>
                      </a>
                    )}
                    {!!siteConfig.email1 && (
                      <a className="text-lightest" href={'mailto:' + siteConfig.email1}>
                        <br />
                        <FontAwesomeIcon icon={faEnvelope} className="mr-5" /> {siteConfig.email1}
                      </a>
                    )}
                  </Fragment>
                )}
              </div>
              {!!footer?.footerPage?.languages?.length && (
                <div
                  className="col-md-9 col-12"
                  dangerouslySetInnerHTML={{
                    __html: mockHtml + footer.footerPage.languages[0].title.body || '',
                  }}
                ></div>
              )}
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default Footer;
